import { StyleSheet, Dimensions } from "react-native";

const { width } = Dimensions.get("window");
const isSmallDevice = width < 600;
const isTablet = width < 1500 && width > 600;

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const styles = StyleSheet.create({
	body: {
		flex: 1,
		backgroundColor: "#214332",
	},
	background: {
		flex: 1,
	},
	section: {
		flex: 1,
		minHeight: "100vh",
	},
	titleText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 80 : isTablet ? 100 : 125,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.25)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginTop: isSmallDevice ? "2.5%" : "1%",
		zIndex: 1,
	},
	headerText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 40 : isTablet ? 90 : 100,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.25)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginTop: isSmallDevice ? "2.5%" : "1%",
	},
	inlineNavBar: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		marginTop: 10,
		position: "relative",
		zIndex: 0,
		marginBottom: "35px",
	},
	navBarText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: isSmallDevice ? 13 : isTablet ? 30 : 35,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 1)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		paddingHorizontal: isSmallDevice ? 10 : 20,
		cursor: "pointer",
	},
	navBarHeader: {
		height: 65,
		backgroundColor: "#15221d",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingLeft: 25,
		paddingRight: 25,
		position: "relative",
		zIndex: 1000,
		elevation: 10,
	},
	navBarHeaderText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: 25,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		paddingRight: 20,
	},
	navLogo: {
		width: 74,
		height: 45,
	},
	mainContainer: {
		width: isSmallDevice ? "80%" : 1492,
		height: isSmallDevice ? 257 : 514,
		borderRadius: 99,
		backgroundColor: "#214332",
		margin: "auto",
		marginTop: "2.5%",
		alignItems: "center",
	},
	mainContainerText: {
		width: isSmallDevice ? "80%" : 1352,
		height: 375,
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 20 : 55,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		margin: "auto",
	},
	innerContainer: {
		width: isSmallDevice ? "80%" : isTablet ? "80%" : 986,
		height: isSmallDevice ? "auto" : isTablet ? 125 : 154,
		borderRadius: isSmallDevice ? 25 : 50,
		backgroundColor: "#214332",
		marginBottom: isSmallDevice ? 10 : 20,
		paddingHorizontal: isSmallDevice ? 5 : 10,
		paddingVertical: isSmallDevice ? 10 : 20,
		marginHorizontal: "auto",
		marginTop: isSmallDevice ? 10 : 20,
	},
	containerHeaderText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 15 : isTablet ? 28 : 32,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginHorizontal: "auto",
		marginBottom: isSmallDevice ? 2 : 5,
	},
	containerDescriptionText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 11 : isTablet ? 20 : 25,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginHorizontal: "auto",
		paddingHorizontal: isSmallDevice ? 10 : 5,
		marginBottom: isSmallDevice ? 2 : 5
	},
	innerContainer1: {
		width: isSmallDevice ? "80%" : 986,
		height: isSmallDevice ? "auto" : "80%",
		borderRadius: isSmallDevice ? 25 : 50,
		backgroundColor: "#214332",
		paddingHorizontal: isSmallDevice ? 5 : 10,
		paddingVertical: isSmallDevice ? 10 : 15,
		marginHorizontal: "auto",
		marginTop: isSmallDevice ? 10 : 20,
	},
	containerHeaderText1: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 15 : isTablet ? 28 : 32,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginHorizontal: "auto",
		marginBottom: isSmallDevice ? 2 : 5,
	},
	containerDescriptionText1: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 11 : isTablet ? 20 : 25,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginHorizontal: "auto",
		paddingHorizontal: isSmallDevice ? 10 : 5,
		marginBottom: isSmallDevice ? 2 : 5
	},
	menusContainer: {
		flex: 1,
		flexDirection: "row",
		margin: "auto",
	},
	calendarContainer: {
		flex: 1,
		flexDirection: "row",
		margin: "auto",
	},
	eventsContainer: {
		padding: 25
	}
});

export default styles;
